import {useState, useRef, useEffect, MutableRefObject} from "react";
import styles from "../Recovery.module.scss";
import { Drawer } from "@material-ui/core";
import ViewCommentDrawer from "./ViewCommentDrawer";
import EditCommentDrawer from "./EditCommentDrawer";
import { createRecoveryViewComments } from "../../../../../../common/ApiHelpers";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  getEmail,
  getUserId,
  getUserName,
} from "../../../../../App/AppReducer";

type RecoveryviewNotesProps = {
  item: any;
  fetchRecoveryDataWithoutLoader: Function;
  setLoading: Function;
  metricsDate: string;
  showDrawer: boolean;
  setShowDrawer: Function
  count: MutableRefObject<number>
};

const RecoveryviewNotes = ({
  item,
  fetchRecoveryDataWithoutLoader,
  showDrawer,
  setShowDrawer,
  setLoading,
  metricsDate,
}:RecoveryviewNotesProps) => {
  const {
    notesWrapper,
    commentsWrapper,
    timeStamp,
    comment,
    notesTextArea,
    drawerHeader,
    recoverViewDrawer,
    // notesDrawerWrapper,
    // notesHeaderWrapper,
    // addedNotes,
    // addNotesCTAWrapper,
    // ownerInfo,
    // addNotesCTA,
    // commentBox,
    // coborrowerWrapper,
    // headerText,
    // otherDetailsWrapper,
    // otherDetailsBox,
    // officeAddress,
    // directorWrapper,
    // heading,
    // address,
    // wrapper,
  } = styles;
  const { notes } = item;
  const userName = useSelector(getUserName);
  const email = useSelector(getEmail);
  const userID = useSelector(getUserId);
  const [commentValue, setCommentValue] = useState("");
  const [createNotes, setCreateNotes] = useState({
    action: "",
    ptpdate: "",
    description: "",
  });
  const count = useRef(0);
  const singleClickTimer = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const textareaRef = useRef(null); // Ref to hold the reference of the textarea element
  const [ saveLoading, setSaveLoading ] = useState(false);
  useEffect(() => {
    if (showTextArea && !showDrawer) {
      //@ts-ignore
      textareaRef?.current?.focus();
    }
  }, [showTextArea, showDrawer]);

  const handleSingleClick = () => {
    setShowTextArea(true);
  };

  const handleDoubleClick = () => {
    setShowDrawer(true);
  };

  const handleChange = (e:any) => {
    setCommentValue(e.target.value);
  };

  const handleBlur = async (item:any) => {
    if (commentValue.trim().length) {
      setLoading(true);
      const { investeeOrgId } = item;
      const timeStamp = moment().format("YYYY-MM");
      const payload = {
        comment: {
          comment: commentValue.trim(),
          owner: userName,
          ownerEmail: email,
          identifierId: `${investeeOrgId}_${timeStamp}`,
          purpose: "Comment Added",
          orgId: investeeOrgId,
          type: "Recovery View",
        },
        ptpDate: "",
        ownerId: userID,
      };
      await createRecoveryViewComments(payload);
      await fetchRecoveryDataWithoutLoader();
      setShowTextArea(false);
      setCommentValue("");
      setLoading(false);
    } else {
      setShowTextArea(false);
    }
  };

  const onClose = (e:any) => {
    e.stopPropagation();
    setShowDrawer(false);
    setIsEditing(false);
    setCreateNotes({
      action: "",
      ptpdate: "",
      description: "",
    });
  };

  const handleClick = (e:any) => {
    e.stopPropagation();
    if (new Date().getMonth() > new Date(metricsDate).getMonth()) {
      return;
    }
    count.current++;
    if (count.current === 1) {
       //@ts-ignore
      singleClickTimer.current = setTimeout(() => {
        count.current = 0;
        handleSingleClick();
      }, 300);
      return;
    } else if (count.current === 2) {
      //@ts-ignore
      clearTimeout(singleClickTimer.current);
      count.current = 0;
      handleDoubleClick();
    }
  };

  const handleCreateNotesSave = async (item:any) => {
    if (createNotes.description.trim().length) {
      setSaveLoading(true)
      const { investeeOrgId } = item;
      const timeStamp = moment().format("YYYY-MM");
      const payload = {
        comment: {
          comment: createNotes.description.trim(),
          owner: userName,
          ownerEmail: email,
          identifierId: `${investeeOrgId}_${timeStamp}`,
          purpose: createNotes.action.length
            ? createNotes.action
            : "Comment Added",
          orgId: investeeOrgId,
          type: "Recovery View",
        },
        ptpDate: createNotes.ptpdate,
        ownerId: userID,
      };
      await createRecoveryViewComments(payload);
      fetchRecoveryDataWithoutLoader();
      setIsEditing(false);
      setCreateNotes({
        action: "",
        ptpdate: "",
        description: "",
      });
      setSaveLoading(false)
    }
  };

  return (
    <div style={{ margin: "10px 5px 10px 0px" }}>
      {!showDrawer && !showTextArea ? (
        <div className={notesWrapper} onClick={handleClick}>
          {notes?.map((e:any) => {
            const noteParts = e.note?.split("\n");
            return (
              <div className={commentsWrapper}>
                <p className={timeStamp}>
                  {e.noteTime} by {e.noteOwner}
                </p>
                {noteParts?.map((part:any) => (
                  <>{part && <p className={comment}>{part}</p>}</>
                ))}
              </div>
            );
          })}
        </div>
      ) : showDrawer ? (
        <Drawer
          anchor="right"
          open={showDrawer}
          onClose={onClose}
          transitionDuration={200}
          PaperProps={{
            style: {
              width: "60%",
              height: "100%",
              padding: "30px 10px 30px 10px",
              borderTopLeftRadius: "20px",
              borderBottomLeftRadius: "20px",
              fontFamily: "Graphik",
            },
          }}
        >
          <main className={recoverViewDrawer}>
            <div className={drawerHeader}>
              <p>Additional Information</p>
              <img
                src="/assets/modal-cross.svg"
                alt="cross"
                onClick={onClose}
              />
            </div>

            {isEditing ? (
              <EditCommentDrawer
                item={item}
                setIsEditing={setIsEditing}
                setCreateNotes={setCreateNotes}
                createNotes={createNotes}
                handleCreateNotesSave={handleCreateNotesSave}
                saveLoading={saveLoading}
              />
            ) : (
              <ViewCommentDrawer
                item={item}
                setIsEditing={setIsEditing}
                metricsDate={metricsDate}
              />
            )}

            {/* <section className={coborrowerWrapper}>
            <p className={headerText}>Co-borrower Details</p>
          </section> */}
            {/* <section className={otherDetailsWrapper}>
              <p className={headerText}>Other Details </p>
              <div className={otherDetailsBox}>
                <div className={officeAddress}>
                  <p className={heading}>Office Address</p>
                  <p className={address}>
                    A-4202, FIORENZA CHSL, NEAR HUB MALL, GOREGAON EAST, MUMBAI,
                    Maharashtra, 400063, India
                  </p>
                </div>
                <div className={directorWrapper}>
                  <div className={wrapper}>
                    <p>Director Details</p>
                    <div>
                      <img src="/assets/arrowwithbluebg.svg" alt="redirect" />
                      <p>View Now</p>
                    </div>
                  </div>

                  <div className={wrapper}>
                    <p>User Details</p>
                    <div>
                      <img src="/assets/arrowwithbluebg.svg" alt="redirect" />
                      <p>View Now</p>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
          </main>
        </Drawer>
      ) : (
        <textarea
          cols={4}
          className={notesTextArea}
          value={commentValue}
          onChange={handleChange}
          onBlur={() => {
            handleBlur(item);
          }}
          ref={textareaRef}
        />
      )}
    </div>
  );
};

export default RecoveryviewNotes;
