import React from 'react';

export default function RenderTextBox({ state, setState, detectChange, setDetectChange, category, disabled }) {
    const handleChange = (val, index) => {
        const obj = {...detectChange};
        if (!obj[category]) {
            obj[category] = true;
            setDetectChange(obj);
        }
        if (state instanceof Array) {
            const arr = [...state];
            arr[index] = val;
            setState(arr);
        }
        else {
            setState(val);
        }
    }

    if (state instanceof Array) {
        return state.map((item, index) => (
            <textarea
                key={index}
                className='w-100'
                placeholder='Conditions will be commented here'
                required
                disabled={disabled}
                onChange={e => handleChange(e.target.value, index)}
                value={item}
            />
        ));
    }
    return (
        <textarea
            className='w-100'
            placeholder='Conditions will be commented here'
            required
            disabled={disabled}
            onChange={e => handleChange(e.target.value)}
            value={state}
        />
    );
}
