import { isEmpty } from 'lodash';
import styles from './UploadDoc.module.scss';
import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import callApi from '../../../../../../../../../util/apiCaller';
import { showNotification } from '../../../../../../../../../util/utility';
import { getUserName } from '../../../../../../../../App/AppReducer';
import { getInvesteesOrganization } from '../../../../../../../AdminReducers';
import { API_STATUS, DEAL_DOC_CATEGORY_ID, DOC_SERVICE_RESPONSE, GENERIC_ERROR, ZEUS_EMAIL_KYC_AUDIT_TRAIL } from '../../../../../../../../../constants/enums';
import { DEAL_DOCS_TYPES, DOC_KEY_MAPPING } from '../consts/consts';
import ConfirmDeleteModal from '../../../../../../../../../components/ConfirmDeleteModal/ConfirmDeleteModal';

export default function UploadDoc({
    preSignedUrl,
    onSuccess,
    docTypeName,
    docId,
    docTypeId,
    flowId,
    validateBeforeUpload,
    comment,
    section,
    validateBeforeDelete,
    onDeleteSuccess,
    beneficialOwnerId,
    beneficialOwnerName,
    description,
    doProductSign,
    showUploadNew = true,
    setShowModal,
    refId,
    refName,
}) {
    const { StyledContentContainerSingle, StyledUploadContainerSingle } = styles;
    const FILE_SIZE = 11000000;
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [showConfirmDeletePopUp, setShowConfirmDeletePopUp] = useState(false);
    const inputFileRef = useRef();
    const callingApi = useRef(false);
    const userName = useSelector(getUserName);
    const investeeOrgData = useSelector(getInvesteesOrganization);
    const investeeOnboardingId = investeeOrgData?.investee_onboarded_id;
    const investeeOrgName = investeeOrgData?.name;
    const isCompanyActive = investeeOrgData?.status === "Active";

    useEffect(() => {
        if (callingApi.current) handleSubmission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFiles]);
    
    const handleFilesValidation = (files) => {
        let message = '';
        let isValid = true;
        let sizeFailure = false;
        files &&
            files.forEach((file) => {
                // const splitter = file.name.split('.');
                // const extension = splitter[splitter.length - 1];
                if (file.size > FILE_SIZE) sizeFailure = true;
            });
        if (files && files.length > 1) {
            isValid = false;
            message = "You cannot upload more than one files at a time";
        }
        else if (sizeFailure) message = 'You cannot upload files greater than 10MB';
        return {
            isValid,
            message,
            sizeFailure
        };
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = 'copy';
    };
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        // validate before uploading
        const { isValid, message } = validateBeforeUpload ? validateBeforeUpload() : {
            isValid: true
        };
        if (!isValid) showNotification("Error", message);
        else {
            onFileChangeCapture(e, true);
            e.dataTransfer.clearData();
        }
    };

    const uploadNewFile = () => {
        // validate before uploading
        const { isValid, message } = validateBeforeUpload ? validateBeforeUpload() : {
            isValid: true
        };
        if (!isValid) showNotification("Error", message);
        else inputFileRef.current.click();
    }

    const onFileChangeCapture = async (e, isDropped = false) => {
        const filesArr = isDropped ? [...e.dataTransfer.files] : [...e.target.files];
        let filteredFilesArray;
        let files;
        const { isValid, message, sizeFailure } =
            handleFilesValidation(filesArr);
        if (!isValid) showNotification('Error', message);
        else {
            if (sizeFailure) {
                showNotification('Error', message);
                filteredFilesArray = filesArr.filter((file) => file.size < FILE_SIZE);
            }
            files = filteredFilesArray ? filteredFilesArray : filesArr;
            callingApi.current = true;
            setSelectedFiles(files);
        }
    };

    const downloadFile = async ({ preSignedUrl, fileName }) => {
        let url = preSignedUrl;
        if(!doProductSign){
            const res = await callApi(`file/downloadFilePresignedForUnsignedKycDoc?fileId=${docId}`, "post", {}, {}, false, false, false, false, false, true);
            if (res?.responseData?.responseCode === DOC_SERVICE_RESPONSE.SUCCESS && res?.signedUrl) {
                url = res?.signedUrl;
                fileName = getFileNameFromSignedUrl(url);
            }
            else {
                let message = res.responseData?.responseMessage ?? GENERIC_ERROR;
                if(message === "Metadata not found for file") message = "Unsigned File Not Present"
                showNotification("Error", message);
                return
            }
        
        } 

        const response = await fetch(url);
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }

    const uploadDocument = async ({ file }) => {
        let purpose = "single_upload";
        try {
            const data = { url: '' };
            const orgName = investeeOrgName.split(' ');
            let investeeOrgNameToSend = orgName[0] + ' ' + (orgName[1] ?? "");
            investeeOrgNameToSend += '_' +(beneficialOwnerName ?? description ?? "");
            let categoryId = Number(process.env.REACT_APP_KYC_CATEGORY_ID);
            if(docTypeName.includes("Deal Doc")){
                categoryId = Number(DEAL_DOC_CATEGORY_ID);
            }
            const signResponse = await callApi("file/signedUrl", 'post', {
                categoryId ,
                fileName: file.name,
                docTypeName : docTypeName?.split("(")[0],
                investeeOrgName: investeeOrgNameToSend
            }, {}, false, false, false, false, false, true);
            data.url = signResponse.signedUrl;
            // const newFile = new File([file], getFileNameFromSignedUrl(data.url));
            // const newData = { newFile };
            // const formData = new FormData();
            // for (const name of Object.keys(newData)) formData.append(name, newData[name]);
            const requestOptions = {
                method: 'put',
                body: file,
                headers: new Headers({
                    'Content-Type': file.type,
                }),
            };
            if (data.url) {
                const uploadResponse = await fetch(data.url, requestOptions);
                if (uploadResponse.ok) {
                    const data_to_post = {
                        categoryId,
                        fileName: getFileNameFromSignedUrl(data.url),
                        fileSizeKb: file.size / 1_000,
                        fileType: file.type,
                        uploadedBy: userName,
                        flowId,
                        docTypeId,
                        id: docId,
                        comment,
                        beneficiaryId: beneficialOwnerId,
                        beneficiaryName: beneficialOwnerName,
                        description,
                        refId,
                        refName,
                        emailAddress: ZEUS_EMAIL_KYC_AUDIT_TRAIL
                    };
                    const updateResponse = await callApi("file/saveMetadata", 'post', data_to_post, {}, false, false, false, false, false, true);
                    if (updateResponse?.responseData?.responseCode === DOC_SERVICE_RESPONSE.SUCCESS) {
                        let instantSign = isCompanyActive && doProductSign;
                        const fileMetadata = updateResponse?.fileMetadata;
                        if (onSuccess) onSuccess(fileMetadata);
                        //deal level addn docs are stored only in doc service
                        if (fileMetadata?.id) {
                        
                            let data_to_post = {};
                        
                            if(docTypeName.includes("Additional Doc")){
                                purpose = "array_update";
                                data_to_post = {
                                    additional_documents: {
                                        meta_data: {
                                            doc_link: fileMetadata?.id,
                                            doc_name: file.name
                                        }, instant_signing: instantSign,
                                        description: description,
                                        cp_key_name : ''
                                    }
                                };
                            } else { 
                                let keyName = DOC_KEY_MAPPING[docTypeName?.split("(")[0]];
                                const dealDocKeyCheck = docTypeName?.split("_")[0]
                                if(keyName === undefined && DEAL_DOCS_TYPES.includes(dealDocKeyCheck)){
                                    keyName = DOC_KEY_MAPPING[dealDocKeyCheck];
                                    section = "deal_docs"
                                }
                                data_to_post[keyName] = {};
                                data_to_post[keyName].doc_link = fileMetadata?.id;
                                data_to_post[keyName].doc_name = file.name;
                                data_to_post[keyName].source = '';
                                data_to_post[keyName].instant_signing = instantSign;
                                if (beneficialOwnerId) data_to_post[keyName].user_id = beneficialOwnerId;
                            }
                            const updateResponse2 = await callApi(`investee-kyc-application/${investeeOnboardingId}/metadata-update?purpose=${purpose}&section=${section}`, "post", data_to_post);
                            if (updateResponse2.status !== API_STATUS.SUCCESS) throw new Error(updateResponse2?.data?.message ?? "Some error occurred");
                            if(docTypeName.includes("Additional Doc") || docTypeName?.split("_")[0]) setShowModal(false);
                        }
                        else throw new Error("Couldn't save metadata for investeeKycApplication");
                        return;
                    } else throw new Error("Couldn't update metaData!");
                }
                else throw new Error(uploadResponse);
            } else throw new Error("Couldn't receive signed url!");
        } catch (error) {
            console.error("UploadDoc: ", error);
            showNotification('Error', 'Failed to upload, something went wrong');
        } finally {
            inputFileRef.current.value = '';
            setIsUploading(false);
        }
    };

    const handleSubmission = async () => {
        // API call to upload file
        const file = selectedFiles[0];
        if (!isEmpty(file)) setIsUploading(true);
        callingApi.current = false;
        await uploadDocument({ file });
    };

    const getFileNameFromSignedUrl = preSignedUrl => {
        const kycFolderNames = {
            KYC_DOCS: "kyc_docs",
            SIGNED_KYC_DOCS: "signed_kyc_docs"
        }
        const kycFolderName = preSignedUrl.includes(kycFolderNames.SIGNED_KYC_DOCS) ? kycFolderNames.SIGNED_KYC_DOCS : kycFolderNames.KYC_DOCS;
        return decodeURIComponent(preSignedUrl)?.split(kycFolderName+'/')[1].split('?')[0];
    }

    const confirmDeleteFile = () => {
        // validate before deleting
        const { isValid, message } = validateBeforeDelete ? validateBeforeDelete() : {
            isValid: true
        };
        if (!isValid) showNotification("Error", message);
        else setShowConfirmDeletePopUp(true);
    }

    const deleteFile = async() => {
        // API call to delete file
        if(docTypeName.includes("Deal Doc")){
            let data_to_post = {
                identifierName : "fileIds",
                docList : [docId]
            }
            const res = await callApi("file/deleteFiles", 'post', data_to_post, {}, false, false, false, false, false, true);
            if (res?.responseData?.responseCode === DOC_SERVICE_RESPONSE.SUCCESS) {
                if (onDeleteSuccess) onDeleteSuccess();
            } else {
                const message = res?.responseData?.responseMessage ?? "Some error occurred";
                showNotification("Error", message);
            }
            setShowConfirmDeletePopUp(false);

        } else {
            let obj = {
                input: {},
            };
            const keyName = DOC_KEY_MAPPING[docTypeName?.split("(")[0]];
            if(docTypeName.includes("Additional")){
                obj = {
                    input: {
                        additional_documents: {
                            meta_data: {
                                doc_link: docId,
                            }, description: description
                        }
                    }
                };
            } else {
                obj.input[keyName] = {};
                if (beneficialOwnerId) obj.input[keyName].user_id = beneficialOwnerId;
            }
            const res = await callApi(`investee-kyc-application/${investeeOnboardingId}/delete-file`, "post", obj);
            if (res?.status === API_STATUS.SUCCESS) {
                if (onDeleteSuccess) onDeleteSuccess();
            }
            else {
                const message = res?.data?.message ?? "Some error occurred";
                showNotification("Error", message);
            }
            setShowConfirmDeletePopUp(false);
        }
        
    }

    if (preSignedUrl) {
        const fileName = getFileNameFromSignedUrl(preSignedUrl);
        return (
            <div
                className='dflexJA flex-wrap DocSection mx-3 mb-3'
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
            >
                <span className='text-truncate' title={fileName}>
                    <img src="/assets/attach-fill.svg" alt="" className='mr-2'  onClick={() => downloadFile({ preSignedUrl, fileName })} />
                    <span>{fileName}</span>
                </span>
                <span>
                    <input
                        type="file"
                        ref={inputFileRef}
                        onChangeCapture={onFileChangeCapture}
                        style={{ display: 'none' }}
                    />
                  {showUploadNew && <span
                        className='upload cp mr-3'
                        onClick={uploadNewFile}
                    >Upload New</span>}
                    <span className='delete cp' onClick={() => confirmDeleteFile({ docId })}>Delete</span>
                </span>
                {showConfirmDeletePopUp && (
                    <ConfirmDeleteModal
                        show
                        onHide={() => setShowConfirmDeletePopUp(false)}
                        deleteHeadingName="Document"
                        deleteName={fileName}
                        onDelete={deleteFile}
                    />
                )}
            </div>
        );
    }
    return (
        <div
            className={`mx-3 mb-3 CheckList ${StyledUploadContainerSingle}`}
            onClick={uploadNewFile}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
        >
            <div className={StyledContentContainerSingle}>
                <img
                    className="mr-1"
                    src="/assets/cloud_upload.svg"
                    alt=""
                    style={{ width: '25px' }}
                />
                <span className="fs10 fw5 clr9 mr-1">Upload Document</span>
            </div>
            <input
                type="file"
                ref={inputFileRef}
                onChangeCapture={onFileChangeCapture}
                style={{ display: 'none' }}
            />
        </div>
    )
}
