import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import callApi from '../../../../../../../../../util/apiCaller';
import { getFeeWithoutGst, getMoratoriumEnum, getMoratoriumOptions, getRoiFromXIRR, priceFormat, showNotification } from '../../../../../../../../../util/utility';
import { getInvesteeOnboardingData, getInvesteesOrganization } from '../../../../../../../AdminReducers';
import moment from 'moment';
import styled from "styled-components";
import PageLoader from '../../../../../../../../../components/loader/PageLoader';
import "../../../../../../../../../css/Status.css";
import SaveSVG from '../../../../../../../../../components/SVGs/SaveSVG';
import DealsOverview from './DealsOverview/DealsOverview';
import dayjs from "dayjs";
import UTC from 'dayjs/plugin/utc';
import { getCheckIfUserHasRelevantPermissions, getSelectedTotIdToRedirect } from '../../../../../../../../App/AppReducer';
import { updateSelectedTotIdToRedirect } from "../../../../../../../../App/AppActions";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { COLLECTION_METHODS, GST_VALUE, LOAN_TYPE, MAX_MORATORIUM, SHOW_NOTIFICATION } from "../../../../../../../../../constants/enums";
import ConfirmTotUpdate from "../../../../../../../../../components/ConfirmTotUpdateModal/ConfirmTotUpdateModal";


dayjs.extend(customParseFormat)
dayjs.extend(UTC);

const InputNewUnsancComp = styled.input`
    border: 1px solid #4C8DFF;
    border-radius: 4px;
    background-color: white;
    color: #4C8DFF;
    height: 28px;
    width:50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
    margin-right: 15px;
    cursor: pointer;
    font-weight: 600;
`;
const BlueBorderBtn = styled.button`
    border: 1px solid #4C8DFF;
    border-radius: 4px;
    background-color: white;
    color: #4C8DFF;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
    margin-right: 15px;
    cursor: pointer;
    font-weight: 600;
`;
const GreenBtn = styled.button`
    border: none;
    height: 28px;
    margin-right: 15px;
    border-radius: 4px;
    background-color: ${({ disabled }) => disabled ? '#4CB71A' : '#00BD00'};
    cursor: ${({ disabled }) => disabled ? 'pointer' : 'no-drop'};
    color: white;
    font-size: 10px;
    font-weight: 600;
`;
const BlueButton = styled.button`
    border: none;
    border-radius: 4px;
    background-color: #4c8dff;  
    color: white;
    height: 28px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
`;

function ManageTOT({ ...props }) {
    const {resetQueryParams} = props;
    const totExpiryDays = 7;
    const dispatch = useDispatch();
    const investeeOrganization = useSelector(getInvesteesOrganization)
    const onboardingData = useSelector(getInvesteeOnboardingData)
    const selectedTotIdToRedirect = useSelector(getSelectedTotIdToRedirect);
    const redirectFromDealsPage = props?.queryParams?.isRedirect;
    const [isEditing, setIsEditing] = useState()
    const [termsList, setTermsList] = useState([])
    const [formData, setData] = useState({})
    const [unusedUnSanctioned, setUnusedSanctioned] = useState()
    const initalRef = useRef(formData)
    const [isIrrOrMaxTenureChanged, setIsIrrOrMaxTenureChanged] = useState(false)
    const [termIndex, setTermIndex] = useState(0)
    const [isSendingTotEmail, setIsSendingTotEmail] = useState(false)
    const [isFetchingTots, setIsFetchingTots] = useState(false);
    const [isTotHistoryEmpty, setIsTotHistoryEmpty] = useState(false)
    const [isSavingTot, setIsSavingTot] = useState(false)
    const [inputNewUnSanc, setInputNewUnSanc] = useState(0);
    const COBORROWER = "Coborrower";
   const [showTotUpdatePopUp, setShowTotUpdatePopUp] = useState(false);
   const [modalMessaging, setModalMessaging] = useState({})
   const [isDebtSyndication, setIsDebtSyndication] = useState(false);
   const [informChildComponent, setInformChildComponent] = useState("1");
   const isUserHasUWViewAccess = useSelector((state) => getCheckIfUserHasRelevantPermissions(state, ["underwriting:edit"]));

    useEffect(() => {
        fetchList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investeeOrganization])

    useEffect(() => {
      if (selectedTotIdToRedirect && !isEmpty(termsList)) {
        let totIndex = termsList.findIndex(
          (tot) => tot?._id === selectedTotIdToRedirect
        );
        dispatch(updateSelectedTotIdToRedirect());
        handleTermChange(totIndex);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTotIdToRedirect, termsList]);

    const fetchList = () => {
        if(!investeeOrganization?._id) return
        setIsFetchingTots(true);
        callApi(`admin/tot-history/investee-organization/${investeeOrganization?._id}/getAllTotHistory`)
            .then((res) => {
                setIsFetchingTots(false);
                if (res.status === 'Success') {
                    setIsTotHistoryEmpty(isEmpty(res.data.totHistory))
                    setUnusedSanctioned(res.data.unusedUnsanctioned)
                    if (!isEmpty(res.data.totHistory)) {
                        setTermsList(res.data.totHistory)
                        let obj = { ...res.data.totHistory[termIndex] };
                        if (isEmpty(obj.cp)) {
                            obj.cp = [""]
                        }
                        if (isEmpty(obj?.cs)) {
                            obj.cs = [""]
                        }
                        setData(obj)
                        initalRef.current = obj
                        return obj;
                    }
                    else{
                        setTermsList(res.data?.totHistory)
                        return res.data?.totHistory;
                    }
                } else {
                    showNotification("error", "Error Fetching Tots")
                }
            })
            .catch((err) => showNotification("error", err))
    }

    const sendTotEmail = (withoutEmail) => {
        setIsSendingTotEmail(true)
        callApi(`admin/tot-history/${formData?._id}/sendTotEmail`, 'post', { sendWithoutEmail: withoutEmail,inputNewUnSanc : (inputNewUnSanc ? inputNewUnSanc : unusedUnSanctioned) })
            .then((res) => {
                setIsSendingTotEmail(false)
                if (res.status === 'Success') {
                    showNotification("success", withoutEmail ? 'Updated : Mail Not Sent' : 'TOT Email sent successfully')
                    fetchList();
                    if(withoutEmail) props.getArrData();
                } else {
                    const message = res.data?.message ? res.data.message : 'Something went wrong'
                    showNotification("error", message)
                }
            })
            .catch((err) => showNotification("error", err))
    }

    const handleChange = (e) => {
        let { name, value, title } = e.target;
        // Take input without GST but save with GST
        if (name === "fees") {
            value = value * GST_VALUE;
        }
        if (name === "irr" || name === "roi" || name === "custom_tenure_limit_max") {
            setIsIrrOrMaxTenureChanged(true)
        }
        if(name === "irr" && isDebtSyndication) {
             setIsIrrOrMaxTenureChanged(false)
        }
        if (name === 'cp' || name === 'cs') {
            setData((prevVal) => {
                let obj = { ...prevVal }
                obj[name][title] = value
                return obj
            })
        } 
        else
            setData((prevVal) => ({ ...prevVal, [name]: value }));
    }

    const addCpCs = (name) => {
        let obj = { ...formData }
        obj[name]?.push("")
        setData(obj)
    }

    const InputField = ({ ansType, options, name, value, index, allowEditAfterAccept,editBlocked }) => {
        switch (ansType) {
            case 'dropdown':
                return <select name={name} selected={value} value={value} onChange={handleChange} disabled={(!allowEditAfterAccept && formData?.tot_status === "Accepted")}>
                    {options.map((op, idx) => {
                        if (op instanceof Object)
                            return <option key={idx} value={op?.value}>{op?.text}</option>
                        return <option key={idx} value={op}>{op}</option>
                    })}
                </select>
            case 'textarea':
                return <textarea
                    name={name}
                    value={value}
                    style={{
                        width: '100%',
                        borderColor: '#4c8dff',
                        borderRadius: '3px',
                        fontSize: '10px'
                    }}
                    onChange={handleChange} />
            default:
                return <input
                    title={index}
                    name={name}
                    value={value}
                    type={ansType}
                    style={{ fontSize: '10px' }}
                    disabled={editBlocked}
                    onChange={handleChange} />
        }
    }

    const handleCancelClick = () => {
        if (formData?._id) {
            setIsEditing(false)
            setData(initalRef.current)
        } else {
            setTermsList(prevVal => {
                let newList = [...prevVal];
                newList.splice(0, 1);
                setFormDataFromObj(newList[0])
                initalRef.current = { ...newList[0] }
                return newList;
            })
            setIsEditing(false);
        }
    }

    const handleTermChange = (index) => {
        let obj = { ...termsList[index] };
        if (isEmpty(obj.cp)) {
            obj.cp = [""]
        }
        if (isEmpty(obj.cs)) {
            obj.cs = [""]
        }

        if(obj.identifier === "DebtSyndication") setIsDebtSyndication(true)
        setIsEditing(false)
        setData(obj)
        setTermIndex(index)
        initalRef.current = { ...obj }
    }

    const shouldNotAllowAddingTerms = () => {
        for (let term of termsList) {
            const { tot_status } = term;
            if (tot_status === "Sent" || tot_status === "Created") {
                showNotification("error", "There's ToT present with Created or Sent Status, Please Update that one !");
                return true;
            }
        }
        return false;
    }


    const setFormDataFromObj = (res) => {
        let obj = checkObjForCpCs(res);
        setData(checkObjForCpCs(res))
        initalRef.current = obj
    }

    const checkObjForCpCs = (obj) => {
        if (obj && isEmpty(obj?.cp))
            obj.cp = [""]

        if (obj && isEmpty(obj?.cs))
            obj.cs = [""]

        return obj;
    }

    const getPriceFromIrr = (id) => {
        callApi('admin/getPriceFromIrr', 'post', { tot_id: id, investee_org_id: investeeOrganization?._id })
            .then(res => {
                if (res.status === 'Success') {
                    setFormDataFromObj(res.data.totHistory)
                    setTermsList(prevList => {
                        prevList[termIndex] = checkObjForCpCs(res.data.totHistory);
                        return prevList;
                    })
                    setIsIrrOrMaxTenureChanged(false)
                }
            })
    }

    const handleObjToSave = (data) => {
        data = removeKeysIfExist(data);
        data.cp = data.cp?.filter(cp => cp !== "")
        data.cs = data.cs?.filter(cs => cs !== "")
        data.mab = data.mab ?? 0
        return data
    }

    const removeKeysIfExist = (obj) => {
        const CREATED_AT = "created_at";
        const UPDATED_AT = "updated_at";
        const DEFAULT_TOT_ID = "default_tot_id";
        const IS_DELETED = "is_deleted";
        if (CREATED_AT in obj) delete obj[CREATED_AT];
        if (UPDATED_AT in obj) delete obj[UPDATED_AT];
        if (DEFAULT_TOT_ID in obj) delete obj[DEFAULT_TOT_ID];
        if (IS_DELETED in obj) delete obj[IS_DELETED];
        return obj;
    }

    const handleUserUpdateConfirmation = (data) =>{
     setShowTotUpdatePopUp(true);
     setModalMessaging(data.messaging)
    }
    const handleUserUpdateCancel = ()  =>{
        setShowTotUpdatePopUp(false);
        handleCancelClick();
    }
    // Updating a Term
    const handleConfirmUpdate = () =>{
        setShowTotUpdatePopUp(false);
        handleSaveClick({adminUpdateOveride : true});
    }

    const handleSaveClick = ({adminUpdateOveride }) => {
        setIsSavingTot(true);
        let obj = handleObjToSave({ ...formData });
        obj.new_unsanctioned_limit = Number(obj.new_unsanctioned_limit) ?? 0;
        if(isDebtSyndication) {
            
            let message = '';
            let isValid = true;

            if(Number(obj.irr) <=0 || Number(obj.maxIrr) <=0) {
                message = "All details are not filled";
                isValid = false;
            }else if((Number(obj.maxIrr) < Number(obj.irr))){
                message = "Maximum ROI cannot be less than the Minimum ROI";
                isValid = false;
            }else if((Number(obj.custom_tenure_limit_min) > 0 && Number(obj.custom_tenure_limit_max) >0 )&& Number(obj.custom_tenure_limit_max) < Number(obj.custom_tenure_limit_min)){
                message = "Maximum Tenure cannot be less than the Minimum Tenure"
                isValid = false;
            }

            if(!isValid){
                showNotification(SHOW_NOTIFICATION.ERROR,message);
                return;
            }
         }

        const body = {
            stage_id: obj?.stage_id,
            tot_id: obj?._id,
            tots: [obj] ,
            allowUpdateAnyway : adminUpdateOveride,
        };
        callApi("admin/tot-history/tot-history-update", 'post', body).then((response) => {
            if (response.status === 'Success') {
                if(response.data?.messaging?.showModal && !adminUpdateOveride){
                    handleUserUpdateConfirmation(response.data)
                    return;
                }

                if (isIrrOrMaxTenureChanged) {
                    getPriceFromIrr(response.data?.totData?.[0]?.tots)
                } else {
                    setFormDataFromObj(response.data?.totData?.[0]?.tots)
                    setTermsList(prevList => {
                        prevList[termIndex] = checkObjForCpCs(response.data?.totData?.[0]?.tots);
                        return prevList;
                    })
                }
                if (response.data?.totData?.[0]?.triggerCreated) {
                    showNotification("Success", "Email trigger created successfully");
                }
                fetchList();
                setIsEditing(false)
                setIsSavingTot(false);
                setInformChildComponent(informChildComponent === "1" ? "2" : "1");
               
            } else {
                if(response?.data?.message) showNotification("Error", response?.data?.message);
                else showNotification("Error", "Tot not updated");
            }
            setIsSavingTot(false);
        })
    }

    const FORM = [
        {
            Header: 'New Unsanctioned Limit (Lacs)',
            toDisplayVal: <>{Number.parseFloat(formData?.new_unsanctioned_limit).toFixed(2)} Lacs</>,
            ansType: 'number',
            value: formData?.new_unsanctioned_limit,
            name: 'new_unsanctioned_limit',
            allowEditAfterAccept: true,
            editBlocked:false
        },
        {
            Header: 'XIRR (Price Max tenure) (%)',
            toDisplayVal: (<div className="d-flex justify-content-between">
                <div>{formData?.irr ? formData?.irr : '_'}%</div>
                <div className="overview-border1 fs8 px-3 py-1">{Number((formData?.max_tenure_price * 100).toFixed(4)).toFixed(2)}%</div>
            </div>),
            ansType: 'number',
            value: formData?.irr,
            name: 'irr',
            allowEditAfterAccept: false,
            editBlocked: true
        },
        {
            Header: 'ROI (%)',
            toDisplayVal: (<div className="d-flex justify-content-between">
                <div>{formData.identifier === "DebtSyndication" ? `${formData?.irr}% - ${formData?.maxIrr}` : formData?.roi ?? '_'}%</div> 
            </div>),
            ansType: 'number',
            value: formData.identifier === "DebtSyndication" ? formData?.irr : formData?.roi,
            name: formData.identifier === "DebtSyndication" ? 'irr' : 'roi',
            allowEditAfterAccept: true,
            editBlocked: false,
            ...(formData.identifier === "DebtSyndication" && {
                subQ: {
                    ansType: 'number',
                    value: formData?.maxIrr,
                    name: 'maxIrr',
                    allowEditAfterAccept: true
                }
            })
        },
        {
            Header: 'Tenure (months)',
            toDisplayVal: <>{formData?.custom_tenure_limit_min} - {formData?.custom_tenure_limit_max}</>,
            ansType: 'number',
            value: formData?.custom_tenure_limit_min,
            name: 'custom_tenure_limit_min',
            subQ: {
                ansType: 'number',
                value: formData?.custom_tenure_limit_max,
                name: 'custom_tenure_limit_max',
                allowEditAfterAccept: true
            },
            allowEditAfterAccept: true
        },
        {
            Header: 'Fee (without GST) (%)',
            toDisplayVal: (<div className="d-flex justify-content-between">
                {/* Take input without GST but save with GST */}
                <div>{formData?.fees ? getFeeWithoutGst(formData.fees) : "None"}%</div>
                {formData?.is_recur_fee_waived ?
                    <span className='bgclr8 color11 fs9 br2 px-3 py-1 ml-2'>Waived</span> :
                    <span className='Accepted-tot-status fs9 br2 px-1 py-1 ml-2'>Not Waived</span>}
                {formData?.feeRes?.isDiscountApplicable && (
                    <span className="discount-applicable-status fs9 br2 py-1">Discounted</span>
                )}
            </div>),
            ansType: 'number',
            value: formData?.fees ? getFeeWithoutGst(formData.fees) : null, // Take input without GST but save with GST
            name: 'fees',
            subQ: {
                ansType: 'dropdown',
                value: formData?.is_recur_fee_waived,
                name: 'is_recur_fee_waived',
                options: [{ value: true, text: 'Waived' }, { value: false, text: 'Not Waived' }],
                allowEditAfterAccept: false
            },
        },
        {
            Header: 'Fee Model',
            toDisplayVal: formData?.fee_model ? formData?.fee_model : '_',
            ansType: 'dropdown',
            options: ['Flat Fee'],
            value: formData?.fee_model,
            name: 'fee_model',
            allowEditAfterAccept: true,
        },
        {   
            Header: 'Moratorium (Upto 3 Months)',
            toDisplayVal: getMoratoriumEnum(formData?.moratorium_months) + ` month${getMoratoriumEnum(formData?.moratorium_months)>1?'s':''}`,
            ansType: 'dropdown',
            options:getMoratoriumOptions(MAX_MORATORIUM) ,        
            value: getMoratoriumEnum(formData?.moratorium_months),
            name: 'moratorium_months',
            allowEditAfterAccept: true
        },
        {
            Header: 'Minimum Balance (INR)',
            toDisplayVal: <>{formData?.mab}</>,
            ansType: 'number',
            value: formData?.mab,
            name: 'mab',
            allowEditAfterAccept: true
        },
    ]

    const handleUpdateClick = () =>{
        if(!isUserHasUWViewAccess) {
            showNotification(SHOW_NOTIFICATION.ERROR,"You don't have access to update the TOT");
            return;
        }
        setIsEditing(true);
        const data = {...formData};
        setData(data);
    } 

    useEffect(() => {
       const { queryParams } = props;
       if(queryParams?.isRedirect && !isEmpty(termsList)){
        const index = termsList.findIndex((obj) => obj?._id === queryParams?.totId);
        let obj = { ...termsList[index] };
        if (isEmpty(obj.cp)) {
            obj.cp = [""]
        }
        if (isEmpty(obj?.cs)) {
            obj.cs = [""]
        }
        setIsEditing(false)
        setData(obj)
        setTermIndex(index)
        initalRef.current = { ...obj }
       }
    },[redirectFromDealsPage,termsList,props.queryParams])

    const checkIfThereAreCollectionMethodsToShow = () => {
        if (isEmpty(formData?.secondary_rcm)) return false;
        for (let method in formData?.secondary_rcm) if (method !== "MAB") return true;
        return false;
    }
    const capitalizeFirstLetter=(string)=>{
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const isOnboardingTot = (currentTot) => {
        if (!currentTot || 
            !currentTot?.onboarding_id || 
            currentTot?.add_on_limit_id || 
            (currentTot?.totLoanType === LOAN_TYPE.DEBT_SYNDICATION)
        ) {
            return false;
        }
        return true;
    }

    const renderInputAndButtons = () => (
        <div className="dflex">
            {formData?.totLoanType !== LOAN_TYPE.DEBT_SYNDICATION &&
                <BlueBorderBtn disabled={isSendingTotEmail} onClick={() => {
                    if (!isSendingTotEmail) sendTotEmail(true)
                }}>
                    {!isSendingTotEmail ? 'Send Without Email' : 'Sending ...'}
                </BlueBorderBtn>
            }
            <span>
                {formData?.tot_status !== "Accepted" &&
                    <>
                        {/* <BlueBorderBtn>Preview</BlueBorderBtn> */}
                        {formData?.tot_status === "Updated Not Sent"
                            && !investeeOrganization.backgroundProcessRunning &&
                            <GreenBtn disabled={isSendingTotEmail} onClick={() => {
                                if (!isSendingTotEmail) sendTotEmail(false)
                            }}>
                                <img src="/assets/email-icon.svg" alt="" style={{ marginRight: '5px' }} />
                                {!isSendingTotEmail ? 'Email User' : 'Sending ...'}
                            </GreenBtn>}
                    </>
                }
            </span>
        </div>
    );

    return (
        <div className="row p-4 m-0">
            <div className='col-md-4'>
                <div className="overview-border p-3">
                    <div className="dflex1 p-1 bgclr2 br4">
                        {isFetchingTots && <PageLoader showOverlay />}
                        <div className="w-50 fs10 fw300">Available Trading Limit</div>
                        <div className="w-50 color6 fs12">{priceFormat(props.available_trade_limit)}</div>
                    </div>
                    <div className="dflex1 p-1 bgclr2 br4">
                        {isFetchingTots && <PageLoader showOverlay />}
                        <div className="w-50 fs10 fw300">Unused UnSanctioned</div>
                        <div className="w-50 color6 fs12">{priceFormat(unusedUnSanctioned)} Lac(s)</div>
                    </div>
                </div>
                <div className="overview-border p-3 pb-5 mt-3">
                    <div className="d-flex mb-3">
                        <div className="fs12 fs500">History</div>
                    </div>
                    {termsList?.map((term, index) =>
                        <div
                            key={index}
                            className={termIndex === index ? "dflex1 p-1 br4 mt-2" : "dflex1 p-1 bgclr2 br4 mt-2"}
                            style={termIndex === index ? { backgroundColor: '#E6F1FF', boxShadow: '0 0 9px 2px rgba(0,0,0,0.12)', border: '1px solid #4C8DFF' } : {}}
                        >
                            <div className="fs10 fw300">{`${term?.identifier === 'DebtSyndication' ? 'Debt' : 'RRF'} - ${moment(term?.created_at).format('DD-MMM-YYYY hh:mm:ss')}`}</div>
                            <div className={`${term?.tot_status}-tot-status TotComStatus color6 fs12`}>{term?.tot_status}</div>
                            <div className="color4 fs12 mr-2" style={{ cursor: 'pointer' }} onClick={() => handleTermChange(index)}>View</div>
                        </div>
                    )}
                </div>
            </div>
            {showTotUpdatePopUp &&(  <ConfirmTotUpdate
     show={modalMessaging.showModal}
     onHide={handleUserUpdateCancel} 
     header={modalMessaging.header}
     title={
        <>
        Changing terms at this stage will{" "}
        <span className="Bold">{modalMessaging.changingTermsMessage}</span> about this
        change.
        </>
    }
     onDelete = {handleConfirmUpdate}
     showMessage2 = {modalMessaging.showSharedMfaSlMessge}
     />)}
            {!isEmpty(termsList) &&
                <div className='col-md-8'>
                    <div className="overview-border">
                        <div className="row mt-4 mx-0 px-3">
                            <div className="col-md-6 py-3 pl-0">
                                <div className="fs12 fs500 d-flex">Terms of Trade ({formData?.identifier})
                                    <span className={`${formData?.tot_status}-tot-status TotComStatus ml-2 color6 bgclr2`}>{formData?.tot_status}</span>
                                </div>
                            </div>
                            <div className="col-md-6 pr-0">
                                {isEditing ?
                                    <div className='dflex1'>
                                        <div className="fs10 p-0 w-50">ToT Expiry Date</div>
                                        <div className={'p-0 w-50'}>
                                            <input title={"index"}
                                                name={"tot_expiry_date"}
                                                value={dayjs(formData?.tot_expiry_date).format("YYYY-MM-DD")}
                                                type="date"
                                                style={{ fontSize: '10px' }}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className='dflex1 p-1 bgclr2 br4'>
                                        <div className="fs10 fw300 w-50">ToT Expiry Date</div>
                                        <div className="color6 fs12 w-50">{dayjs(formData?.tot_expiry_date).utc().format("DD/MM/YYYY")}</div>
                                    </div>}
                            </div>
                        </div>
                        <div className="row m-0 px-3 pb-3">
                            <div className="col-md-6 mb-3">
                                {FORM.map((i, index) => {
                                    const { toDisplayVal, Header, subQ } = i
                                    return (
                                        <div key={index} className={isEditing ? "row dflex1 p-1 br4 mb-2" : "row dflex1 p-1 bgclr2 br4 mb-2"}>
                                            {isEditing ?
                                                (<>
                                                    <div className="col-md-6 fs10 p-0">{Header}</div>
                                                    <div className="col-md-6">
                                                        <div className="row m-0">
                                                            <div className={(!isNil(subQ)) ? 'col-md-6 p-0 pr-1' : 'col-md-12 p-0'}>
                                                                {InputField(i)}
                                                            </div>
                                                            {subQ && <div className="col-md-6 p-0">
                                                                {InputField(subQ)}
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </>)
                                                : (<>
                                                    <div className="col-md-6 fs10 fw300">{Header}</div>
                                                    <div className="col-md-6 color6 fs12">{toDisplayVal}</div>
                                                </>)
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="col-md-6 mb-3 pr-0">
                                <div className="dflex1 p-2 bgclr2 br4 mb-2">
                                    {isEditing ?
                                        <>
                                            <div className="fs10 p-0 w-50">Fee Discount Expiry Date</div>
                                            <div className={'p-0 w-50'}>
                                                <input title={"index"}
                                                    name={"discount_expiry_date"}
                                                    value={dayjs(formData?.discount_expiry_date).format("YYYY-MM-DD")}
                                                    type="date"
                                                    style={{ fontSize: '10px' }}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="fs10 fw300 w-50">Fee Discount Expiry Date</div>
                                            <div className="color6 fs12 w-50">{dayjs(formData?.discount_expiry_date).utc().format("DD/MM/YYYY")}</div>
                                        </>}
                                </div>
                                <div className="dflex1 p-2 bgclr2 br4 mb-2">
                                    <div className="w-50 fs10 fw300">Previous Unsanctioned Limit</div>
                                    <div className="w-50 bold fs12">{formData?.addingTot ? Number(unusedUnSanctioned)?.toFixedNoRounding(2) : Number(formData?.previous_unsanctioned_limit)?.toFixedNoRounding(2)} Lacs</div>
                                </div>
                                {checkIfThereAreCollectionMethodsToShow() && (
                                    <div className="dflex2 p-2 bgclr2 br4 mb-2">
                                        <div className="w-50 fs10 fw300">Collection Methods</div>
                                        <ul className="w-50 bold fs12 p-0 mb-0">
                                            {Object.keys(formData?.secondary_rcm).map(method => (
                                                (!(method===COBORROWER && isEmpty(formData?.secondary_rcm?.[method]))) && <li key={method}>
                                                    <span className="mr-2">{COLLECTION_METHODS[method]}</span>
                                                    {formData?.secondary_rcm?.[method] ? (
                                                        "start_date" in formData?.secondary_rcm?.[method] ? 
                                                            <span>
                                                                <span>({formData?.secondary_rcm?.[method]?.start_date} - </span>
                                                                <span>{formData?.secondary_rcm?.[method]?.end_date})</span>
                                                            </span> :
                                                        "amount" in formData?.secondary_rcm?.[method] ?
                                                                <span>({formData?.secondary_rcm?.[method]?.amount})</span>
                                                                :
                                                        method===COBORROWER && Array.isArray(formData?.secondary_rcm?.[method]) ? (
                                                            (formData?.secondary_rcm?.[method]).map((item)=>{
                                                                return <div style={{overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis' }}>
                                                                    <span>{capitalizeFirstLetter(item?.type)} : </span>
                                                                    <span>{item?.name}</span>
                                                                </div>
                                                            })
                                                        ): null
                                                    ) : null}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {(formData?.note || isEditing) &&
                                    <div className={isEditing ? "p-2 br4 mb-2" : "p-2 bgclr2 br4 mb-2 pb-5"}>
                                        <div className="w-50 fs10 fw300">Note</div>
                                        {isEditing
                                            ? (InputField({ ansType: 'textarea', value: formData?.note, name: 'note' }))
                                            : <div className="w-50 bold fs12">{formData?.note}</div>
                                        }
                                    </div>
                                }
                                {((formData?.cs?.[0] || formData?.cp?.[0]) || isEditing) &&
                                    <div className={isEditing ? "p-2 br4" : "p-2 bgclr2 br4 mb-2"}>
                                        {
                                            [
                                                { header: 'CP', state: formData?.cp, name: 'cp' },
                                                { header: 'CS', state: formData?.cs, name: 'cs' },
                                            ].map((c, index) => {
                                                const { header, state, name } = c;
                                                return <React.Fragment key={index}>
                                                    {(state?.[0] !== "" || isEditing) &&
                                                        <>
                                                            <div className="w-50 fs10 fw300">{header}</div>
                                                            {isEditing
                                                                ? <>{state?.map((ps, index) => <React.Fragment key={index}>{InputField({ ansType: 'text', value: ps, name, index })} </React.Fragment>)}</>
                                                                : (<ol>{state?.map((ps, index) => <li key={index} className="w-50 bold fs12">{ps}</li>)}</ol>)
                                                            }
                                                            {isEditing &&
                                                                <div className="add-cp">
                                                                    <span onClick={() => addCpCs(name)}>
                                                                        <img src="/assets/BluePlus.svg" alt="plus" />
                                                                        <span>Add {header}</span>
                                                                    </span>
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </React.Fragment>
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <hr className='m-0' />
                        <div className="d-flex align-items-center p-3">
                            <div className='w-50 d-flex'>
                                <div className="fs9 mr-3">Last Update: <span className="fw300">{moment(formData?.updated_at).format("DD MMM YYYY hh:mm:ss")}</span></div>
                                {formData?.tot_sent_date ?
                                    <div className="fs9">Last email: <span className="fw300">{moment(formData?.tot_sent_date).format('DD MMM YYYY hh:mm:ss')}</span></div> : null}
                            </div>
                            {isEditing ? (
                                <div className="d-flex ml-auto">
                                    <button className="mr-4 btn6 btnht1 d-flex align-items-center justify-content-center ml-auto" onClick={handleCancelClick}>
                                        <img
                                            src='../../../../../assets/icon-clear.svg'
                                            alt='cross'
                                            className='mr-2'
                                            style={{ width: '11px' }}
                                        />
                                        <span>Cancel</span>
                                    </button>
                                    <button className="save-btn btn5 btnht1 d-flex align-items-center justify-content-center" onClick={() => {
                                        if (formData?._id) handleSaveClick({adminUpdateOveride : false})
                                    }}
                                    disabled={isSavingTot}
                                    >
                                        <SaveSVG />
                                        <span>Save</span>
                                    </button>
                                </div>)
                                : (
                                    <div className="d-flex justify-content-end w-50 ml-auto">
                                        {(isOnboardingTot(formData) || formData?.tot_status === "Accepted") && renderInputAndButtons()}
                                        <BlueButton onClick={handleUpdateClick}>
                                            <img src="/assets/update.svg" alt="plus" className='mr-1' />
                                            Update
                                        </BlueButton>
                                    </div>
                                )
                            }
                        </div>
                    </div>

                    <DealsOverview
                        investeeOrganizationName={investeeOrganization?.name}
                        investeeOrgId={investeeOrganization?._id}
                        displayName={investeeOrganization?.display_name}
                        tradingLimit={investeeOrganization?.trading_limit}
                        informChildComponent={informChildComponent} //passing to re-render Deal Component
                        totId={formData?._id}
                        new_unsanctioned_limit={formData?.new_unsanctioned_limit}
                        fetchTotList={fetchList}
                        queryParams={props.queryParams}
                        resetQueryParams={resetQueryParams}
                        tenure={formData?.custom_tenure_limit_max}
                        totSize={formData?.new_unsanctioned_limit}
                        totData={formData}
                    />

                </div>
            }
        </div>
    );
}
export default ManageTOT;