import { useEffect, useState } from "react";
import RecurTable from "../../../../../components/RecurTable/RecurTable";
import { showNotification } from "../../../../../util/utility";
import AdminHead from "../../../components/AdminHead";
import PageLoader from "../../../../../components/loader/PageLoader";
import { Tabs } from "../tabs";
import styles from "./Recovery.module.scss";
import "react-datepicker/dist/react-datepicker.min.css";
import "../../../../../css/ReactCustomLibrary.scss";
import ToggleButton from "../../../../../components/ToggleButton/ToggleButton";
import DatePicker from "react-datepicker";
import { getRecoveryViewDetails } from "../../../../../common/ApiHelpers";
import { debounce, filter, isEmpty } from "lodash";
import RecoveryTableRow from "./component/RecoveryTableRow";
import moment from "moment";

export const Recovery = ({ tab, setTab }) => {
  const {
    CollectionsHeaderRight,
    tableWrapper,
    investorOrgName,
    CommonSpacingOntTop,
    RemainingOnlyFilter,
    companyName,
    lastActionWrapper,
    dueAmountWrapper,
    portfolioWrapper,
    ownerWrapper,
    progressTop,
    greenTextValues,
    redTextValues,
    progressValues,
    progressBar,
    filledBar,
    upperAmount,
    lowerAmount,
    dpdBucket,
    ptpDate,
    ptpDateWrapper,
    nodata,
    header_input,
    HeaderSubPart,
    Green,
    Grey,
    Blue,
    Red,
  } = styles;

  const [metricsDate, setMetricsDate] = useState(new Date());
  const [totalAndRemain, setTotalAndRemain] = useState({
    totalPaid: 0,
    totalRemaining: 0,
    graphValue: 0,
  });
  const [data, setData] = useState([]);
  const [agents, setAgents] = useState([]);
  const [agentsMap, setAgentsMap] = useState([]);
  const [investorOrgIdNameEntries, setInvestorOrgIdNameEntries] = useState([]);
  const [dpdBuckets, setDpdBuckets] = useState([]);
  const [loader, setLoader] = useState(false);
  const [lenderText, setLenderText] = useState("All Investors");
  const [dpdText, setDPDText] = useState("DPD Bucket");
  const [showRemainingAmtTxns, setShowRemainingAmtTxns] = useState(true);
  const [filterObj, setFilterObj] = useState({
    sortFilter: {
      sortBy: "remainingAmt",
      sortOrder: "desc",
    },
    showRemaining: showRemainingAmtTxns,
    companyName: "",
  });
  const [loading, setLoading] = useState(false);
  const [collectedCompanies, setCollectedCompanies] = useState(0);
  const [remainingCompanies, setRemainingCompanies] = useState(0);
  const [type, setType] = useState("");
  const releaseDate = new Date("2024-06-01T00:00:00"); // June 1, 2024
  const currentDate = new Date(); // Current date

  const toggleRemainingFilter = () => {
    setShowRemainingAmtTxns(!showRemainingAmtTxns);
    setFilterObj({
      ...filterObj,
      showRemaining: !showRemainingAmtTxns,
    });
  };

  const changeQuery = (keyName, keyValue) => {
    setFilterObj({
      ...filterObj,
      [keyName]: keyValue,
    });
    setType(keyValue);
  };

  const investorOrgNameFilter = (e) => {
    if (e.target.value === "All Investors") {
      setFilterObj({
        ...filterObj,
        lenderId: null,
      });
    } else {
      setFilterObj({
        ...filterObj,
        lenderId: e.target.value,
      });
    }
    const selectedOptionText = e.target.options[e.target.selectedIndex].text;
    setLenderText(selectedOptionText);
  };

  const requestSort = (key) => {
    if (
      key === filterObj?.sortFilter?.sortBy &&
      filterObj?.sortFilter?.sortOrder === "desc"
    ) {
      setFilterObj({
        ...filterObj,
        sortFilter: {
          sortBy: key,
          sortOrder: "asc",
        },
      });
    } else {
      setFilterObj({
        ...filterObj,
        sortFilter: {
          sortBy: key,
          sortOrder: "desc",
        },
      });
    }
  };

  const dpdBucketFilter = (e) => {
    if (e.target.value === "DPD Bucket") {
      setFilterObj({
        ...filterObj,
        dpdBucket: null,
      });
    } else {
      setFilterObj({
        ...filterObj,
        dpdBucket: e.target.value,
      });
    }
    setDPDText(e.target.value);
  };

  const renderRows = () => {
    return (
      <tbody>
        {!isEmpty(data) ? (
          data?.map((item, index) => (
            <RecoveryTableRow
              item={item}
              setLoader={setLoader}
              agentsMap={agentsMap}
              agents={agents}
              fetchRecoveryDataWithoutLoader={fetchRecoveryDataWithoutLoader}
              index={index}
              setLoading={setLoading}
              metricsDate={metricsDate}
            />
          ))
        ) : (
          <tr>
            <td className={nodata} colSpan={12}>
              No Data
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  const getHeaders = () => [
    {
      jsx: (
        <span>
          <input
            type="text"
            placeholder="Company"
            value={type}
            onChange={(e) => changeQuery("companyName", e.target.value)}
            style={{ border: "none" }}
          />
        </span>
      ),
    },
    {
      jsx: <span>Collected / Total</span>,
    },
    {
      jsx: (
        <span>
          Due Amount{" "}
          <button
            onClick={() => requestSort("remainingAmt")}
            style={{ border: "none", marginLeft: "7px" }}
          >
            &#8645;
          </button>
        </span>
      ),
    },
    {
      jsx: <span className={header_input}>DPD Bucket</span>,
    },
    {
      jsx: (
        <span className={header_input}>
          Portfolio Risk{" "}
          <button
            onClick={() => requestSort("totalRiskAmt")}
            style={{ border: "none", marginLeft: "7px" }}
          >
            &#8645;
          </button>
        </span>
      ),
    },
    {
      jsx: (
        <span className={header_input}>
          PTP Date{" "}
          <button
            onClick={() => requestSort("ptpDate")}
            style={{ border: "none", marginLeft: "7px" }}
          >
            &#8645;
          </button>
        </span>
      ),
    },
    {
      jsx: <span className={header_input}>Owner</span>,
    },
    {
      jsx: <span>Last Action</span>,
    },
    {
      jsx: <span>Notes</span>,
    },
  ];

  const debouncedSearch = debounce((value) => {
    if (!isEmpty(filterObj)) fetchRecoveryData();
  }, 600);

  useEffect(() => {
    debouncedSearch(filterObj);
    return debouncedSearch.cancel;
  }, [metricsDate, filterObj]);

  const fetchRecoveryData = async () => {
    setLoader(true);
    const data = {
      yyyyMM: moment(metricsDate).format("YYYY-MM"),
      filters: filterObj,
    };
    const res = await getRecoveryViewDetails(data);
    setLoader(false);
    if (res) {
      setData(res.recoveryViewItems);
      const totalPaid = res.totalPaid ?? 0;
      const totalRemaining = res.totalRemaining ?? 0;
      setCollectedCompanies(res.collectedCompanies);
      setRemainingCompanies(res.remainingCompanies);
      setTotalAndRemain({
        totalPaid: totalPaid,
        totalRemaining: totalRemaining ?? 0,
        graphValue: (totalPaid / totalRemaining) * 100,
      });
      const agentsMap = res.agents.map((item) => {
        return {
          label: item.agentName,
          value: item.agentName,
        };
      });
      setAgents(res.agents);
      setAgentsMap(agentsMap);

      // Convert the object to an array of entries
      let entries = Object.entries(res?.investorOrgIdNameMap);
      // Sort the entries by value
      entries.sort((a, b) => a[1].localeCompare(b[1]));
      entries.unshift(["All Investors", "All Investors"]);
      let filteredEntries = [];
      for (let item of entries)
        if (item[1] !== lenderText) filteredEntries.push(item);
      setInvestorOrgIdNameEntries(filteredEntries);

      const dpdBuckets = res?.dpdbuckets ?? [];
      dpdBuckets.unshift("DPD Bucket");
      const dpdBucketFiltered = [];
      for (let item of dpdBuckets)
        if (item !== dpdText) dpdBucketFiltered.push(item);
      setDpdBuckets(dpdBucketFiltered);

      if (filterObj?.dpdBucket) {
        setDPDText(filterObj?.dpdBucket ?? "DPD Bucket");
      }

      if (filterObj?.lenderId) {
        setLenderText(
          res?.investorOrgIdNameMap[filterObj?.lenderId] ?? "All Investors"
        );
      }
    } else {
      const message = res?.error ?? "Failed to fetch Collection Data";
      showNotification("Error", message);
    }
  };

  const fetchRecoveryDataWithoutLoader = async () => {
    const data = {
      yyyyMM: moment(metricsDate).format("YYYY-MM"),
      filters: filterObj,
    };
    const res = await getRecoveryViewDetails(data);
    if (res) {
      setData(res.recoveryViewItems);
      setTotalAndRemain({
        totalPaid: res.totalPaid,
        totalRemaining: res.totalRemaining,
        graphValue: (res.totalPaid / res.totalRemaining) * 100,
      });
      const agentsMap = res.agents.map((item) => {
        return {
          label: item.agentName,
          value: item.agentName,
        };
      });
      setAgents(res.agents);
      setAgentsMap(agentsMap);
    } else {
      const message = res?.error ?? "Failed to fetch Collection Data";
      showNotification("Error", message);
    }
  };

  return (
    <div className="main-body">
      <AdminHead />
      {loader ? (
        <PageLoader />
      ) : (
        <section className="w-100">
          <div
            className="d-flex justify-content-between align-items-center has-search p-3"
            style={{ height: "100px" }}
          >
            <div className="fs21 fw600">Collections</div>
            {/**should be breaked into another component */}
            <div className={CollectionsHeaderRight}>
              {/* https://reactdatepicker.com/#example-month-picker */}
              {/* create a dropdown of select lender from the response coming in api in res.data.investorOrgIdNameMap */}

              <select
                className={investorOrgName}
                defaultChecked={lenderText}
                onChange={(e) => {
                  investorOrgNameFilter(e);
                }}
              >
                <option value={lenderText}>{lenderText}</option>
                {investorOrgIdNameEntries.map((item, index) => (
                  <option key={index} value={item[0]}>
                    {item[1]}
                  </option>
                ))}
              </select>

              <select
                className={investorOrgName}
                defaultChecked={dpdText}
                onChange={(e) => {
                  dpdBucketFilter(e);
                }}
              >
                <option value={dpdText}>{dpdText}</option>
                {dpdBuckets?.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>

              <div className="custom-DatePicker">
                <DatePicker
                  selected={new Date(metricsDate)}
                  onChange={(date) => {
                    setMetricsDate(date);
                  }}
                  minDate={releaseDate}
                  maxDate={currentDate}
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
              <div className={HeaderSubPart}>
                <div>Complete</div>
                <div>
                  <span className={Green}>{collectedCompanies}</span>
                  <span className={Grey}>
                    /{collectedCompanies + remainingCompanies}
                  </span>
                </div>
              </div>
              <div className={progressTop}>
                <div className={progressValues}>
                  <p
                    className={greenTextValues}
                  >{`₹ ${totalAndRemain.totalPaid?.toLocaleString(
                    "en-IN"
                  )}`}</p>
                  <p
                    className={redTextValues}
                  >{`₹ ${totalAndRemain.totalRemaining?.toLocaleString(
                    "en-IN"
                  )} Remaining`}</p>
                </div>
                <div className={progressBar}>
                  <div
                    className={filledBar}
                    style={{ width: `${totalAndRemain?.graphValue}%` }}
                  ></div>
                </div>
              </div>
              <div className={`${RemainingOnlyFilter} DocumentVaultSwitch`}>
                <span>Show Relevant</span>
                <ToggleButton
                  checked={showRemainingAmtTxns}
                  onChange={toggleRemainingFilter}
                />
              </div>
            </div>
          </div>
          <div className="page-head-bottom-border"></div>
          <Tabs tab={tab} setTab={setTab} />
          <div className={CommonSpacingOntTop}>
            {/* <div className={filter_arr_wrapper}>
              {filterObjEnteries().map((o) => (
                <span onClick={(e) => clearFilters(e, false, o?.key, o?.value)}>
                  {o?.value} <img src={clearIcon} alt="clear icon" />
                </span>
              ))}
              {filterObjRep() && (
                <span
                  className={clear_all}
                  onClick={(e) => clearFilters(e, true)}
                >
                  Clear All Filter
                </span>
              )}
            </div> */}
            {/* <div className={tableWrapper}> */}
            {/* <CommonTable
                columns={columns}
                data={data}
                tableClassName={recoveryViewTable}
                hover={false}
              /> */}
            <RecurTable
              headers={getHeaders()}
              renderRows={renderRows()}
              loading={loading}
            />
            {/* </div> */}
          </div>
        </section>
      )}
    </div>
  );
};
