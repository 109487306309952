import AdminHead from "../../../components/AdminHead";
import React, {useEffect, useState} from "react";
import {Tabs} from "../tabs";
import RecurTable from "../../../../../components/RecurTable/RecurTable";
import {debounce, isEmpty} from "lodash";
//@ts-ignore
import styles from './NachCollections.module.scss';
import {
    cancelNachRequest,
    editNachDetails,
    getAdhocNachDetails,
    getCreateNach
} from "../../../../../common/ApiHelpers.ts";
import moment from "moment";
import {getS3IconLink, numberOnly, showNotification} from "../../../../../util/utility";
import {ICON_NAMES} from "../../../../../common/IconNames.ts";
import {SHOW_NOTIFICATION} from "../../../../../constants/enums";
import ReactTooltip from "react-tooltip";

type NachCollectionsProps = {
    tab: string;
    setTab: React.Dispatch<React.SetStateAction<string>>;
    beta: boolean;
    setBeta: React.Dispatch<React.SetStateAction<boolean>>;
}
type RowData = {
    companyName: string;
    investorName: string;
    totalDpdAmount: string;
    availableNachValue: string;
    nachAmountScheduled: string;
    nachDateScheduled: string;
    status: string;
    daysNotAllowedToSchedule: string[];
    investeeOrganizationId: string;
    investorOrganizationId: string;
    nachFailureReason?: string[];
    adhocNachId?: string;
    collectedAmount?: string;
}
export const NachCollections = (props: NachCollectionsProps) => {
    const {tab, setTab, beta, setBeta} = props;
    const [loader, setLoader] = useState(false)
    const [rowData, setRowData] = useState<RowData[]>([]);
    const [isEditing, setIsEditing] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [showTooltip, setShowTooltip] = useState<number | null>(null);
    const getTdsStatusColors = (status: string) => {
        let color = '';
        let banner = '';
        switch (status) {
            case 'Scheduled':
                color = '#4C8DFF';
                banner = '#E4EEFF';
                break;
            case 'Open':
                color = '#989898';
                banner = '#F4F4F4';
                break;
            case 'Collected':
                color = '#49A71B';
                banner = '#E5FCDE';
                break;
            case 'Bounced':
                color = '#D06D6D';
                banner = '#FBEEEE';
                break;
        }
        return {
            color,
            banner,
        };
    };

    const fetchRowData = async (companyName?: string) => {
        setLoader(true)
        const payload = {
            searchFilters: {
                companyName: companyName ?? ''
            }
        }
        const res = await getAdhocNachDetails(payload);
        if (res) {
            setRowData(res.adhocNachLineItemList);
        }
        setLoader(false)
    }
    useEffect(() => {
        if (isEmpty(rowData)) fetchRowData();
    }, [rowData]);

    const createNach = async (item: RowData) => {
        setLoading(true);
        const payload = {
            scheduleDate: '',
            scheduleAmount: item.nachAmountScheduled,
            investeeOrgId: item.investeeOrganizationId,
            investorOrgId: item.investorOrganizationId
        }
        if(item.nachDateScheduled){
            payload['scheduleDate'] = moment(item.nachDateScheduled).format('DD/MM/YYYY');
        }else{
            payload['scheduleDate'] = moment().add(1,'day').format('DD/MM/YYYY');
        }
        const res = await getCreateNach(payload);
        if (res) {
            setIsEditing(null);
            setLoading(false);
            fetchRowData(searchString ?? '');
        } else {
            showNotification(SHOW_NOTIFICATION.ERROR, 'Failed to create NACH');
            setLoading(false);
        }
    }
    const editNach = async (item: RowData) => {
        setLoading(true)
        const payload = {
            scheduleDate: moment(item.nachDateScheduled).format('DD/MM/YYYY'),
            scheduleAmount: item.nachAmountScheduled,
            adhocNachId: item.adhocNachId
        }
        const res = await editNachDetails(payload);
        if (res) {
            setLoading(false);
            setIsEditing(null);
            fetchRowData(searchString ?? '');

        } else {
            setLoading(false);
            showNotification(SHOW_NOTIFICATION.ERROR, 'Failed to edit NACH');
        }
    }
    const deleteNach = async (item: RowData) => {
        setLoading(true);
        const payload = {
            adhocNachId: item.adhocNachId
        }
        const res = await cancelNachRequest(payload);
        if (res) {
            setLoading(false);
            setIsEditing(null)
            fetchRowData(searchString ?? '');
        } else {
            setLoading(false);
            showNotification(SHOW_NOTIFICATION.ERROR, 'Failed to delete NACH');
        }
    }

    const getHeaders = () => {
        return [
            {
                jsx: <span style={{width: '12.5%'}}>Company Name</span>,
            },
            {
                jsx: <span style={{width: '12.5%'}}>Investor Name</span>,
            },
            {
                jsx: <span style={{width: '12.5%'}}>Total Due</span>,
            },
            {
                jsx: <span style={{width: '12.5%'}}>Available Value</span>,
            },
            {
                jsx: <span style={{width: '12.5%'}}>NACH Amount</span>,
            },
            {
                jsx: <span style={{width: '12.5%'}}>NACH Date</span>,
            },
            {
                jsx: <span style={{width: '12.5%'}}>Status</span>,
            },
            {
                jsx: <span style={{width: '12.5%'}}>{'     '}</span>,
            },
        ];
    }
    const checkAllowedDate = (item: any, value: string) => {
        let disabledDate = item?.daysNotAllowedToSchedule?.map((item: any) => {
            return `${moment().format('YYYY')}-${moment().format('MM')}-${item}`;
        });
        if (disabledDate?.includes(value)) {
            showNotification(SHOW_NOTIFICATION.ERROR, 'Another NACH already exists for the selected date! Please choose a different date');
            return false;
        }
        ;
        return true;
    }
    const handleNachAmountChange = (value: string, index: number) => {
        let totalDue = rowData[index].totalDpdAmount;
        let availableAmount = rowData[index].availableNachValue;
        let temp = [...rowData];
        if (rowData[index].hasOwnProperty('availableNachValue') && rowData[index].hasOwnProperty('totalDpdAmount')) {
            if (Number(value) > Math.min(Number(totalDue), Number(availableAmount))) {
                temp[index].nachAmountScheduled = Math.min(Number(totalDue), Number(availableAmount)).toString();

                setRowData(temp);
                return;
            }
        }
        temp[index].nachAmountScheduled = value;
        setRowData(temp);
    }
    const handleSearch = (value: string) => {
        setSearchString(value);
    }
    const debouncedSearch = debounce((value: string) => {
        fetchRowData(value);
    }, 1000)
    useEffect(() => {
        debouncedSearch(searchString);
        return debouncedSearch.cancel;
    }, [searchString]);
    const renderTextForTooltip = (item: RowData) => {
        if (item.status === 'Bounced' || item.status === 'Failed') {
            if(item.nachFailureReason) return (
                <>
                    <p style={{margin:0}}><b>Bounced Amount:</b> {item.nachAmountScheduled}</p>
                    <p style={{margin:0}}><b>Reason:</b> {item.nachFailureReason.join(',')}</p>
                </>
            )
            else return `Bounced Amount: ${item.nachAmountScheduled}`
        } else if (item.status === 'Collected') {
            return (
                <>
                    {item.collectedAmount && <span style={{color:'black'}}><b>Collected:</b> {item.collectedAmount}</span>}
                </>
            )
        }
    }
    const renderIcons = (item: RowData, index: number) => {
        if (item.status === 'Scheduled' && isEditing !== index && item.nachDateScheduled >= moment().startOf('day').toISOString()) {
            return (
                <div style={loading ? {cursor: 'no-drop'} : {cursor: 'pointer'}} className={styles.iconsContainer}>
                    <img onClick={() => setIsEditing(index)} src={getS3IconLink(ICON_NAMES.EDIT_ICON_BLUE_NACH)}
                          alt="Edit"/>
                    <img onClick={() => deleteNach(item)} src={getS3IconLink(ICON_NAMES.DELETE_ICON_RED)} alt="Delete"/>
                </div>
            );
        }
        if ((item.status === 'Scheduled' || item.status === 'Open') && isEditing === index) {
            return (
                <div style={loading ? {cursor: 'no-drop'} : {cursor: 'pointer'}} className={styles.iconsContainer}>
                    <img onClick={() => {
                        if (item.status === 'Open') createNach(item);
                        if (item.status === 'Scheduled') editNach(item);
                    }} src={getS3IconLink(ICON_NAMES.SAVE_ICON_BLUE)} alt="Edit"/>
                    <img onClick={() => {
                        setIsEditing(null);
                        fetchRowData()
                    }} src={getS3IconLink(ICON_NAMES.CROSS_RED_NACH)} alt="Delete"/>
                </div>
            );
        }
        if (item.status === 'Open') {
            return (
                <div style={loading ? {justifyContent: 'flex-end', cursor: 'no-drop'} : {
                    justifyContent: 'flex-end',
                    cursor: 'pointer'
                }} className={styles.iconsContainer}>
                    <img onClick={() => setIsEditing(index)} style={{marginRight: '0.5rem'}}
                         src={getS3IconLink(ICON_NAMES.EDIT_ICON_BLUE_NACH)} alt="Edit"/>
                </div>
            )
        }
        if (item.status === 'Bounced' || item.status === 'Failed' || item.status === 'Collected') {
            return (
                <>
                    <div style={loading ? {justifyContent: 'flex-end', cursor: 'no-drop'} : {
                        justifyContent: 'flex-end',
                        cursor: 'pointer'
                    }} className={styles.iconsContainer}>

                        <img onMouseEnter={() => setShowTooltip(index)} onMouseLeave={() => setShowTooltip(null)}
                             src={getS3IconLink(ICON_NAMES.INFO_BLACK)}
                             alt="Delete"/>
                        {
                            showTooltip === index && (
                                <span style={{color:'black'}} className={styles.tooltip}>
                                    {renderTextForTooltip(item)}
                                </span>
                            )
                        }
                    </div>
                </>
            );
        }
    }
    const getRows = () => {
        if (isEmpty(rowData)) {
            return (
                <tbody className={styles.noDataInTable}>
                <tr>
                    <td colSpan={6}>No Queries available</td>
                </tr>
                </tbody>
            );
        } else {
            return (
                <tbody className={styles.noDataInTable}>
                {
                    rowData.map((item: RowData, index: number) => {
                        return (
                            <tr key={index}>
                                <td className={styles.tableText}>{item.companyName ?? '-'}</td>
                                <td className={styles.tableText}>{item.investorName ?? '-'}</td>
                                <td className={styles.tableText}>₹{parseInt(item?.totalDpdAmount??0,10)===0?'-':parseInt(item?.totalDpdAmount??0,10) ?? '-'}</td>
                                <td className={styles.tableText}>₹{item.availableNachValue ?? '-'}</td>
                                <td className={styles.tableText}>{
                                    isEditing === index ? <input
                                        type={'text'}
                                        className={styles.NachAmountInput}
                                        value={item.nachAmountScheduled}
                                        onChange={(e) => handleNachAmountChange(numberOnly(e.target.value), index)}
                                    /> : item.hasOwnProperty('nachAmountScheduled') ?
                                        <p style={{margin: 0}}>₹{parseInt(item.nachAmountScheduled,10)}</p> : '-'
                                }</td>
                                <td className={styles.tableText}>
                                    {isEditing === index ? <input
                                        type={'date'}
                                        className={styles.NachdateInput}
                                        defaultValue={moment().add(1, 'day').format('YYYY-MM-DD')}
                                        value={item.nachDateScheduled ? moment(item.nachDateScheduled).format('YYYY-MM-DD') : moment().add(1, 'day').format('YYYY-MM-DD')}
                                        min={moment().add(1,'day').format('YYYY-MM-DD')}
                                        max={moment().endOf('month').format('YYYY-MM-DD')}
                                        onChange={(e) => {
                                            //update rowData
                                            let check = checkAllowedDate(item, e.target.value);
                                            if (!check) {
                                                return;
                                            }
                                            let temp = [...rowData];
                                            if(e.target.value < moment().add(1,'day').format('YYYY-MM-DD')){
                                                showNotification(SHOW_NOTIFICATION.ERROR, 'Please select a valid date');
                                                return;
                                            }
                                            temp[index].nachDateScheduled = e.target.value;
                                            setRowData(temp);
                                        }
                                        }
                                    /> : item.hasOwnProperty('nachDateScheduled') ?
                                        <p style={{margin: 0}}>{moment(item.nachDateScheduled).format('DD MMM YYYY')}</p> : '-'}
                                </td>
                                <td className={styles.tableText}>
                                    {isEditing !== index ? <p style={{
                                            marginRight: '1rem',
                                            color: getTdsStatusColors(item.status).color,
                                            background: getTdsStatusColors(item.status).banner,
                                        }} className={styles.tableBanner}>{item.status ?? '-'}</p>
                                        :
                                        <div
                                            className={styles.tableBanner}
                                            style={{
                                                display: 'none',
                                                marginRight: '1rem',
                                                color: getTdsStatusColors(item.status).color,
                                                background: getTdsStatusColors(item.status).banner,
                                            }}
                                        ></div>
                                    }
                                </td>
                                <td style={{paddingRight: '1rem'}} className={styles.tableText}>
                                    {renderIcons(item, index)}
                                </td>
                            </tr>
                        );
                    })
                }
                </tbody>
            )
        }
    }
    return (
      <div className="main-body">
        <AdminHead />
        <section className="w-100">
          <div
            className="d-flex justify-content-between align-items-center has-search p-3"
            style={{ height: "100px" }}
          >
             <div className="fs21 fw600">NACH Collections</div>
            <input
              type="text"
              className={`form-control ${styles.searchInput}`}
              placeholder="Search Company"
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              style={{ width: "25rem", marginRight: "1rem" }}
            />
          </div>
          <Tabs tab={tab} setTab={setTab} />
          <div className={styles.CommonSpacingOntTop}>
            <RecurTable
              headers={getHeaders()}
              loading={loader}
              renderRows={getRows()}
              changeColorOnHover={false}
              style={{}}
              headerStyle={{}}
            />
          </div>
        </section>
      </div>
    );
}

export default NachCollections;